import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import { Typo } from '../../fragment';
import useStyles from './SectionItem.styles';
import { MdChevronRight as RightIcon, MdEdit as EditIcon } from 'react-icons/md';
import useWindowDimensions, { MOBILE_SIZE } from '../../hooks/useWindowDimensions';

const SectionItem = ({ pvDataID, checked, isMultiSelect, withJurisdiction, ...props }) => {
  const jurisdictions = props?.jurisdictions?.length ? props.jurisdictions : [];
  const exceptions = props?.pvExceptions?.length ? props.pvExceptions : [];
  const { width } = useWindowDimensions();
  const isMobile = width <= MOBILE_SIZE;
  const theme = useTheme();
  const classes = useStyles(theme, isMultiSelect ? checked : false);
  const targetNumber = `Target ${props?.pvTargetNumber}:`;
  const itemCount = `${props?.pvItems?.length || 0} item${props?.pvItems?.length ? 's' : ''}`;

  return (
    <>
      <Box sx={classes.body} onClick={() => props.onClick('view', pvDataID)}>
        <Box
          sx={{
            ...classes.textContent,
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? '10px' : '16px',
          }}>
          <Box
            sx={{
              ...classes.wrapper,
              flexDirection: isMobile ? 'row' : 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              gap: isMobile ? '8px' : '0',
              flexWrap: 'wrap',
            }}
            style={{ minWidth: '45px', maxWidth: isMobile ? 'unset' : '80px', width: '100%' }}>
            <Typo variant='body1' sx={classes.tagTitle}>
              {targetNumber}
            </Typo>
            <Box sx={classes.tag}>{itemCount}</Box>
            {withJurisdiction && isMobile && jurisdictions.length > 0
              ? jurisdictions.map((title, idx) => (
                  <Box
                    key={`${title}_${idx}`}
                    sx={classes.tagAssign}
                    style={{ color: '#FFF', backgroundColor: '#2961FD' }}>
                    {title}
                  </Box>
                ))
              : null}
            {!!withJurisdiction && isMobile && exceptions.length > 0 && (
              <Box sx={classes.tagAssign} style={{ color: '#D0021B' }}>
                Exceptions
              </Box>
            )}
            {!!withJurisdiction && isMobile && jurisdictions.length === 0 && (
              <Box sx={classes.tagAssign}>No Assignment</Box>
            )}
          </Box>
          <Box sx={classes.wrapper}>
            <Box sx={classes.titleWrapper}>
              <Typo
                variant={'body1'}
                color={'common.black'}
                fontWeight={500}
                fontSize={isMobile ? '0.75rem' : '1rem'}>
                {props?.pvTargetName}
              </Typo>
              {!!withJurisdiction &&
                !isMobile &&
                jurisdictions.length > 0 &&
                jurisdictions.map((title, idx) => (
                  <Box
                    key={`${title}_${idx}`}
                    sx={classes.tagAssign}
                    style={{ color: '#FFF', backgroundColor: '#2961FD' }}>
                    {title}
                  </Box>
                ))}
              {!!withJurisdiction && !isMobile && exceptions.length > 0 && (
                <Box sx={classes.tagAssign} style={{ color: '#D0021B' }}>
                  Exceptions
                </Box>
              )}
              {!!withJurisdiction && !isMobile && jurisdictions.length === 0 && (
                <Box sx={classes.tagAssign}>No Assignment</Box>
              )}
            </Box>
            <Typo
              variant={'body1'}
              fontWeight={400}
              fontSize={12}
              sx={{
                color: '#474747',
                opacity: withJurisdiction ? (jurisdictions.length ? 1 : 0.2) : 1,
              }}>
              {props?.pvDescription}
            </Typo>
          </Box>
        </Box>
        <Box
          sx={{
            ...classes.btn,
            position: isMobile ? 'absolute' : 'unset',
            top: isMobile ? '6px' : 'unset',
            right: isMobile ? '6px' : 'unset',
          }}
          hidden={props.hideEditBtn}>
          <EditIcon
            style={{ height: isMobile ? '18px' : '24px', width: isMobile ? '18px' : '24px' }}
            onClick={() => props.onClick('edit', pvDataID)}
          />
        </Box>
        <Box sx={classes.btn} hidden={props.hideGoToBtn || isMobile}>
          <RightIcon style={{ height: '24px', width: '24px' }} />
        </Box>
      </Box>
      {!!props.isLastChild && (
        <Box sx={{ minHeight: '24px', height: '24px', width: '100%', bgcolor: 'transparent' }} />
      )}
    </>
  );
};

SectionItem.propTypes = {
  pvDataID: PropTypes.number,
  pvTargetName: PropTypes.string,
  pvDescription: PropTypes.string,
  pvTargetNumber: PropTypes.string,
  tagItem: PropTypes.string,
  jurisdictions: PropTypes.array,
  hideEditBtn: PropTypes.bool,
  hideGoToBtn: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  withJurisdiction: PropTypes.bool,
  onClick: PropTypes.func,
  isLastChild: PropTypes.bool,
};

SectionItem.defaultProps = {
  hideEditBtn: false,
  hideGoToBtn: false,
  isMultiSelect: false,
  withJurisdiction: false,
};

export default SectionItem;
