import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './MobileCardView.styles';
import Box from '@mui/material/Box';

const MobileCardView = (props) => {
  const classes = useStyles();
  return (
    <Box sx={classes.container} onClick={props.onClick}>
      <Box sx={classes.wrapper}>
        {props.firstRow && <Box sx={classes.rowWrapper}>{props.firstRow}</Box>}
        {props.secondRow && <Box sx={classes.rowWrapper}>{props.secondRow}</Box>}
        {props.thirdRow && <Box sx={classes.rowWrapper}>{props.thirdRow}</Box>}
      </Box>
    </Box>
  );
};

MobileCardView.propTypes = {
  pvDataID: PropTypes.number,
  firstRow: PropTypes.element,
  secondRow: PropTypes.element,
  thirdRow: PropTypes.element,
  onClick: PropTypes.func,
};

export default MobileCardView;
