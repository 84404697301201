const useStyles = (theme, checked) => ({
  body: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    bgcolor: checked ? '#E2EAFF' : '#fff',
    padding: '8px',
    cursor: 'pointer',
    border: checked ? '1.5px solid #5C87FF' : 'unset',
  },
  textContent: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 0 0',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  tag: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
    gap: '2px',
    borderRadius: '2px',
    bgcolor: checked ? '#2961FD' : '#F0F0F0',
    color: checked ? '#FFF' : '#474747',
    fontSize: '0.75rem',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.65rem',
    },
  },
  tagTitle: {
    fontSize: '1rem',
    fontWeight: 400,
    color: '#707070',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
  btn: {
    color: '#707070',
    '&:hover': {
      color: '#2961FD',
    },
    cursor: 'pointer',
  },
  isChecked: {
    border: '1.5px solid #2961FD',
  },
  tagAssign: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '2px 4px',
    borderRadius: '2px',
    bgcolor: '#E0E0E0',
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'center',
  },
});
export default useStyles;
