import React from 'react';
import PropTypes from 'prop-types';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import useStyles, { ToggleButton } from './ToggleGridButton.styles';

const ToggleGridButton = (props) => {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      sx={classes.rootGroup}
      exclusive
      color='primary'
      variant='contained'
      value={props.value}
      size='small'
      {...props}>
      <ToggleButton value={props.leftTitle} onClick={props.onClick} sx={{ width: '100%' }}>
        {props.leftTitle}
      </ToggleButton>
      <ToggleButton value={props.rightTitle} onClick={props.onClick} sx={{ width: '100%' }}>
        {props.rightTitle}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

ToggleGridButton.prototype = {
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ToggleGridButton.defaultProps = {
  leftTitle: 'Yes',
  rightTitle: 'No',
  value: null,
};

export default ToggleGridButton;
