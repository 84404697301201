// noinspection JSValidateTypes

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Pagination, Paper, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useStyles, { StyledTableCell, StyledTableRow } from './MuiTableGrid.styles';
import useFetchData from './hook/useFetchData';
import Loading from '../Loading';
import MobileCardView from './MobileCardView';
import Typo from '../Typo';
import { FormatDate } from '../../utilities/utils';

const MuiTableGrid = ({ columns, isMobile, ...props }) => {
  const { isFetching, data, count, pageCount } = useFetchData({ ...props });
  const theme = useTheme();
  const classes = useStyles(isMobile);

  useEffect(() => {
    if (count && count > 0) props.callBackCount && props.callBackCount(count);
  }, [count]);

  useEffect(() => {
    if (pageCount && pageCount > 0) props.callBackPageCount && props.callBackPageCount(pageCount);
  }, [pageCount]);

  useEffect(() => {
    props.callBackDataFetching && props.callBackDataFetching(isFetching);
  }, [isFetching]);

  const createSortHandler = (property) => (event) => {
    props.handleRequestSort(event, property);
  };

  if (isFetching || props.isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '80vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Loading />
      </Box>
    );

  return (
    <Paper sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      {isMobile ? (
        <Box sx={{ width: '100%', height: '67vh', overflow: 'auto' }}>
          {data &&
            data.length > 0 &&
            data.map((row, idx) => {
              if (!row.pvDataID || !row.pvTag) return null;
              return (
                <MobileCardView
                  key={row?.pvDataID || idx}
                  pvDataID={row?.pvDataID}
                  firstRow={
                    <Box sx={{ display: 'flex', gap: '6px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '4px',
                          alignItems: 'center',
                          fontSize: '12px',
                        }}>
                        Tag:
                        <Typo sx={{ backgroundColor: '#E0E0E0', color: '#1F1F1F', px: '8px' }}>
                          {row?.pvTag}
                        </Typo>
                      </Box>
                      <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <Typo
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            px: '8px',
                            maxWidth: '120px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}>
                          {row?.pvTypeDescription}
                        </Typo>
                      </Box>
                      <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <Typo
                          sx={{
                            backgroundColor: 'grey',
                            color: 'white',
                            px: '8px',
                            maxWidth: '120px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}>
                          {row?.pvManufacturer}
                        </Typo>
                      </Box>
                    </Box>
                  }
                  secondRow={
                    <Box sx={{ display: 'flex', gap: '4px' }}>
                      <Typo sx={{ fontSize: '14px', fontWeight: 600 }}>{row?.pvSite}</Typo>
                    </Box>
                  }
                  thirdRow={
                    <Box sx={{ display: 'flex', gap: '6px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '4px',
                          alignItems: 'center',
                          fontSize: '12px',
                        }}>
                        Serial:
                        <Typo sx={{ backgroundColor: '#E0E0E0', color: '#1F1F1F', px: '8px' }}>
                          {row?.pvSerialNumber}
                        </Typo>
                      </Box>
                      <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <Typo
                          sx={{
                            backgroundColor: 'grey',
                            color: 'white',
                            px: '8px',
                            maxWidth: '120px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}>
                          {row?.pvLocation}
                        </Typo>
                      </Box>
                      {row?.pvDueDate && (
                        <Typo
                          sx={{
                            fontSize: '12px',
                            maxWidth: '120px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}>
                          {FormatDate(row.pvDueDate, 'MM/DD/YYYY')}
                        </Typo>
                      )}
                    </Box>
                  }
                  onClick={props.onRowClick ? () => props.onRowClick(row) : null}
                />
              );
            })}
        </Box>
      ) : (
        <TableContainer sx={classes.tableContainer}>
          <Table sx={{ minWidth: 700, maxWidth: '1440px' }} stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.field}
                      sortDirection={props.orderBy === column.field ? props.order : false}
                      align={column.numeric ? 'right' : 'center'}
                      style={{ minWidth: `${column.minWidth} px` }}>
                      <TableSortLabel
                        active={props.orderBy === column.field}
                        direction={props.orderBy === column.field ? props.order : 'asc'}
                        onClick={createSortHandler(column.field)}>
                        {column.headerName}
                        {props.orderBy === column.field ? (
                          <Box component='span' sx={visuallyHidden}>
                            {props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                <StyledTableCell style={{ width: '20px' }}></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length > 0
                ? data.map((row, idx) => (
                    <StyledTableRow
                      key={row?.pvDataID || idx}
                      onClick={props.onRowClick ? () => props.onRowClick(row) : undefined}>
                      {columns &&
                        columns.map((column) => (
                          <StyledTableCell
                            component='th'
                            scope='row'
                            key={column.field}
                            align={column.numeric ? 'right' : 'left'}
                            style={{ minWidth: `${column.minWidth} px`, flex: 1 }}>
                            {column.renderCell ? column.renderCell(row) : row[column.field]}
                          </StyledTableCell>
                        ))}
                      <StyledTableCell>
                        <ChevronRightIcon fontSize={'small'} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        sx={classes.pagination}
        shape='rounded'
        count={props.pageTotal}
        page={props.currentPage}
        onChange={(_e, num) => props.onPageChange(num)}
      />
    </Paper>
  );
};

MuiTableGrid.propTypes = {
  dataURL: PropTypes.string,
  filters: PropTypes.string,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  currentPage: PropTypes.number,
  limit: PropTypes.number,
  initialPageTotal: PropTypes.number,
  pageTotal: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowClick: PropTypes.func,
  callBackCount: PropTypes.func,
  callBackPageCount: PropTypes.func,
  callBackDataFetching: PropTypes.func,
  handleRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      numeric: PropTypes.bool,
      headerName: PropTypes.string,
      enabled: PropTypes.bool,
      minWidth: PropTypes.number,
      renderCell: PropTypes.func,
    }),
  ).isRequired,
};

export default MuiTableGrid;
