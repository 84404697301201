import React, { useEffect, useState } from 'react';
import PalmettoSideMenu from '@zawarski/palmetto-react-side-menu';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DeselectOutlinedIcon from '@mui/icons-material/DeselectOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import LightBulbIcon from '@mui/icons-material/Lightbulb';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import GuideIcon from '@mui/icons-material/MenuBook';
import { FaRegFolderOpen as FaRegFolderOpenIcon } from 'react-icons/fa6';
import SitePermissionTypes from '../../../redux/const/sitepermission';

const DrawerMenu = (props) => {
  const navigate = useHistory();
  const user = useSelector((state) => state.rootReducer.user);
  const userFullName = `${user?.ncPersonGivenName} ${user?.ncPersonSurName}`;
  const defaultMenuItems = {
    // This is the top section of the menu
    menu: [
      {
        key: 'dashboard',
        name: 'Home',
        path: '/dashboard',
        startIcon: <HomeIcon />,
      },
      {
        key: 'lcert',
        name: 'Annual Letters of Certification',
        path: '/lcert',
        startIcon: <ReceiptLongIcon />,
      },
      {
        key: 'requirements',
        name: 'Exercises',
        path: '/requirements',
        startIcon: <DeselectOutlinedIcon />,
      },
      {
        key: 'equipment',
        name: 'Equipment',
        path: '/equipment',
        startIcon: <PaymentOutlinedIcon />,
      },
      {
        key: 'redistribution',
        name: 'Redistribution Matrix',
        path: '/redistribution',
        startIcon: <BubbleChartIcon />,
      },
      {
        key: 'filelibrary',
        name: 'File Library',
        path: '/filelibrary',
        startIcon: <FaRegFolderOpenIcon />,
      },
      {
        key: 'training',
        name: 'Training',
        path: '/training',
        startIcon: <LightBulbIcon />,
      },
      {
        key: 'capabilities',
        name: 'Capability Targets',
        path: '/capabilities',
        startIcon: <SquareRoundedIcon />,
      },
      {
        key: 'milestones',
        name: 'Milestones',
        path: '/milestones',
        startIcon: <CalendarViewMonthIcon />,
      },
    ],
    // This is the bottom section of the menu
    section: [
      {
        key: 'user', // this is the unique key identifier
        name: userFullName, // this is the name that will be displayed in the menu
        path: '/settings', // this is the path to the page
        startIcon: <PersonIcon />,
      },
      {
        key: 'guide',
        name: 'View user guide',
        path: '/guide',
        endIcon: <GuideIcon sx={{ color: '#2961fd' }} />,
      },
      {
        key: 'logout',
        name: 'Log out',
        path: '/logout',
        endIcon: <LogoutIcon sx={{ color: '#eb021d' }} />,
      },
    ],
    // This is the version section of the menu
    version: {
      name: 'Version 1.0.0', // Define the version number
      color: '#999', // Define the default color
    },
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activePath, setActivePath] = useState('/dashboard');
  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  const userPermission =
    sessionStorage.getItem('userAuthCreds') &&
    JSON.parse(sessionStorage.getItem('userAuthCreds')).repUserType;

  useEffect(() => {
    function removeItems(array, keyToFind) {
      return array.filter((item) => item.key !== keyToFind);
    }

    if (userPermission) {
      if (userPermission !== SitePermissionTypes.F) {
        let tempMenu = defaultMenuItems.menu;
        tempMenu = removeItems(tempMenu, 'capabilities');
        tempMenu = removeItems(tempMenu, 'milestones');
        // noinspection JSCheckFunctionSignatures
        setMenuItems((prevState) => ({ ...prevState, menu: tempMenu }));
      }
    }
  }, [userPermission]);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
    props.callback && props.callback(open);
  };

  useEffect(() => {
    function handleScroll() {
      setIsDrawerOpen(true);
    }

    window.addEventListener('toggle-sidebar', handleScroll);

    return () => {
      window.removeEventListener('toggle-sidebar', handleScroll);
    };
  }, []);

  const handleViewGuide = async () => {
    const a = document.createElement('a');
    a.href = 'guides/REPUserGuide.pdf';
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    document.body.appendChild(a);
    // noinspection JSUnresolvedReference
    a.click();
    document.body.removeChild(a);
    setIsDrawerOpen(false);
  };

  const handleMenuClick = (link) => {
    console.log('link', link);
    setActivePath(link);
    if (link === '/logout') {
      return props.logout && props.logout();
    }
    if (link === '/settings') {
      return null;
    }
    if (link === '/guide') {
      return handleViewGuide();
    }
    navigate.push(link);
    setIsDrawerOpen(false);
  };

  // const handleDrawerClose = () => setIsDrawerOpen(false);

  return (
    <PalmettoSideMenu
      srcLogo={'/images/launcher.png'} // Import the Logo image and pass it as a prop
      menuItems={menuItems}
      isDrawerOpen={isDrawerOpen}
      activePath={activePath}
      activeSubMenu={false}
      programTitle={process.env.REACT_APP_APP_TITLE}
      onDrawerClose={toggleDrawer(false)}
      onMenuClick={handleMenuClick}
      deactivateSubMenu={() => null}
    />
  );
};

export default DrawerMenu;
