const useStyles = (bgcolor, isPrintBtnDisabled, isMobile = false) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // bgcolor: '#fff',
    width: '100%',
    padding: isMobile ? '12px' : bgcolor ? '24px' : '12px',
    gap: '8px',
    bgcolor: bgcolor ? bgcolor : 'unset',
    borderRadius: '4px',
    boxShadow: bgcolor
      ? '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)'
      : 'unset',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: bgcolor ? bgcolor : '#EBEBEB',
    width: '100%',
    height: 'auto',
    gap: bgcolor ? '8px' : '4px',
  },
  title: {
    color: bgcolor ? '#1C43B1' : '#474747',
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    gap: '8px',
    fontSize: '12px',
    bgcolor: '#E0E0E0',
    color: '#1F1F1F',
    padding: '8px 16px',
    borderRadius: '4px',
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      bgcolor: '#D8D8D8',
    },
  },
  editBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    gap: '8px',
    borderRadius: '4px',
    fontSize: '12px',
    bgcolor: '#E2EAFF',
    color: '#1C43B1',
    padding: '4px 8px',
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      bgcolor: '#1C43B1',
      color: '#E2EAFF',
    },
  },
  printBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    gap: '8px',
    borderRadius: '4px',
    fontSize: '12px',
    bgcolor: isPrintBtnDisabled ? '#F0F0F0' : '#E0E0E0',
    color: isPrintBtnDisabled ? '#C2C2C2' : '#1F1F1F',
    padding: '4px 8px',
    fontWeight: 700,
    cursor: isPrintBtnDisabled ? '' : 'pointer',
    ...(isPrintBtnDisabled
      ? {}
      : {
          '&:hover': {
            bgcolor: '#9c9c9c',
          },
        }),
  },
  btnsWrapper: {
    display: 'flex',
  },
});
export default useStyles;
