const useStyles = (theme, isMobile = false) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    bgcolor: '#2961FD',
    // padding: '8px',
  },
  tabs: {
    color: '#fff',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      textTransform: 'none', // Adjust text transformation
      fontWeight: 500, // Change font weight
      color: 'rgba(255, 255, 255, 0.7) !important',
      height: '48px !important',
      margin: '0 !important',
      fontSize: isMobile ? '12px' : '16px',
      minHeight: 'unset !important',
      borderRadius: '0 !important',
    },
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
      color: '#fff !important',
      height: '48px !important',
      margin: '0 !important',
    },
  },
  bottomTabs: {
    '& .MuiBottomNavigationAction-root': {
      bgcolor: '#2961FD',
      maxWidth: 'unset',
      color: 'rgba(255, 255, 255, 0.6)',
      '& .MuiBottomNavigationAction-label': {
        fontSize: '12px',
      },
    },
    '& .MuiBottomNavigationAction-root.Mui-selected': {
      backgroundColor: 'rgba(41, 97, 253, 0.85) !important',
      color: '#fff !important',
    },
  },
});
export default useStyles;
