const useStyles = () => ({
  container: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    padding: '8px',
    gap: '2px',
    backgroundColor: '#FFF',
    overflow: 'hidden',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'rgb(235, 235, 235)',
    borderRadius: '4px',
    padding: '8px',
    width: '100%',
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    gap: '8px',
    padding: '4px 8px',
    width: '100%',
    backgroundColor: '#FFF',
  },
});

export default useStyles;
